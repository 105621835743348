<template>
    <div class="page-loader">
        <div class="loader--container">
            <section class="container 1">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
            <section class="container 2">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
            <section class="container 3">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
            <section class="container 4">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
            <section class="container 5">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
            <section class="container 6">
                <div class="loader">
                    <figure class="front"> </figure>
                    <figure class="back"> </figure>
                    <figure class="right"> </figure>
                    <figure class="left"> </figure>
                    <figure class="top"> </figure>
                    <figure class="bottom"> </figure>
                </div>
            </section>
        </div>
        <h1 v-if="text" class="page-loader__text">{{ text }}</h1>
    </div>
</template>

<script>
export default {
    name: 'PageLoader',
    props: {
        text: String
    },
    mounted () {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy () {
        document.body.style.overflow = 'auto';
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/rfs.scss';

.page-loader {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 10000;
    position: fixed;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.55);

    .loader--container{
        width: 50vw;
        display: flex;
        margin-bottom: 40px;
        justify-content: center;

        .container {
            width: 100px;
            height: 50px;
            position: relative;
            perspective: 1000px;
            margin: 0 0 0 -55px;

            .loader {
                width: 100%;
                height: 100%;
                position: absolute;
                transform-style: preserve-3d;
                animation: rotate 2.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.05s infinite;
                transform: rotateY(60deg) rotateZ(-10deg) rotateX(0deg);

                figure {
                    margin: 0;
                    display: block;
                    position: absolute;
                    border: 1px solid #999;
                    animation: color 2.5s linear .05s infinite;
                }

                .front, .back {
                    width: 97px;
                    height: 65px;
                    background: #fff;
                }

                .right, .left {
                    width: 32px;
                    height: 65px;
                    left: 33px;
                    background: #fff;
                }

                .top, .bottom {
                    width: 97px;
                    height: 32px;
                    top: 16.5px;
                    background: #fff;
                }

                .front {
                    transform: rotateY(0deg) translateZ(16.5px);
                }
                .back{
                    transform: rotateX(180deg) translateZ(16.5px);
                }
                .right{
                    transform: rotateY(90deg) translateZ(49.5px);
                }
                .left{
                    transform: rotateY(-90deg) translateZ(49.5px);
                }
                .top{
                    transform: rotateX(90deg) translateZ(33px);
                }
                .bottom{
                    transform: rotateX(-90deg) translateZ(33px);
                }
            }

            &:nth-child(2){
                .loader{
                    animation-delay: 1.10s;
                    figure {
                        animation-delay: .10s;
                    }
                }
            }

            &:nth-child(3){
                .loader{
                    animation-delay: 1.15s;
                    figure {
                        animation-delay: .15s;
                    }
                }
            }

            &:nth-child(4){
                .loader{
                    animation-delay: 1.20s;
                    figure {
                        animation-delay: .20s;
                    }
                }
            }

            &:nth-child(5){
                .loader{
                    animation-delay: 1.25s;
                    figure {
                        animation-delay: .25s;
                    }
                }
            }

            &:nth-child(6){
                .loader{
                    animation-delay: 1.30s;
                    figure {
                        animation-delay: .30s;
                    }
                }
            }
        }

        // Animations...
        @keyframes rotate {
            0%{
                transform: rotate3d(2, 0, 1, 0deg) rotateY(60deg) rotateZ(-10deg) scale(1);
            }
            50%{
                transform: rotate3d(2, 0, 1, 360deg) rotateY(60deg) rotateZ(-10deg) scale(1);
            }
            80%,100%{
                transform: rotateY(60deg) rotateZ(-10deg) scale(1);
            }
        }

        @keyframes color {
            0%,25%{
                background: #fff;
                border-color: #999;
            }
            50%{
                background: linear-gradient(90deg, #8077EA 13.7%, #EB73FF 94.65%);
                border-color: #ECECEC;
            }
            75%,100%{
                background: #fff;
                border-color: #999;
            }
        }
    }

    .page-loader__text {
        color: lightgrey;
        user-select: none;
        text-align: center;
        white-space: pre-line;
        @include font-size(37px);
        @include margin-top(40px);
        @include margin-bottom(40px);
        font-family: "Montserrat", sans-serif;
    }
}
</style>
