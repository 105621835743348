<template>
    <button class="gradient-btn" :class="{hover}" @click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'GradientButton',
    props: {
        hover: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/rfs.scss';

$space_gray: #3A3B41;
$accent_color1:#82568E;
$accent_color2:#E16B72;

.gradient-btn {
    border: 0;
    z-index: 1;
    margin: 0 auto;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    letter-spacing: 0;
    position: relative;
    padding: 10px 20px;
    align-items: center;
    white-space: nowrap;
    border-radius: 12px;
    text-transform: none;
    display: inline-flex;
    text-decoration: none;
    letter-spacing: 0.7px;
    color: #fff !important;
    justify-content: center;
    transition: all .3s ease;
    @include font-size(18px);
    font-family: "Montserrat", sans-serif;
    background: linear-gradient(to right, $accent_color1 0%, $accent_color2 100%);

    &.hover {
        &:hover {
            opacity: 1;
            transform: translateY(3px);
        }
    }

    svg {
        position: relative;
        display: block;
        width: 16px;
        height: 19px;
        margin-right: 15px;
    }

    span {
        padding-left: 15px;
        border-left: 1px solid rgba(255, 255, 255, .4);
        font-weight: bold;
    }
}
</style>
