/* eslint-disable */
export default {
  data() {
    return {
      showHorizontalBars: false,
      isFullscreen: false,
      showThumbsBar: false
    };
  },
  watch: {
    isFullscreen() {
      if (typeof this.checkMenuDisplayConditions === "function") {
        this.checkMenuDisplayConditions();
      }
    }
  },
  mounted() {
    const fullscreenChangeHandler = (e) => {
      const possibilities = [document.fullscreenElement, document.webkitFullscreenElement, document.mozFullScreenElement, document.msFullscreenElement];
      if (possibilities.includes(null)) {
        this.isFullscreen = false;
      }
      setTimeout(() => {
        if (typeof this.fitScreen === "function") {
          this.fitScreen();
        }
      }, 100);
    };
    document.onfullscreenchange = fullscreenChangeHandler;
    document.onwebkitfullscreenchange = fullscreenChangeHandler;
    document.onmozfullscreenchange = fullscreenChangeHandler;
    document.onmsfullscreenchange = fullscreenChangeHandler;
    const treshold = 0.1;
    window.addEventListener('mousemove', (e) => {
      if (this.isFullscreen) {
        const yAreaSize = window.innerHeight * treshold;
        const yAreas = {
          top: yAreaSize,
          bottom: window.innerHeight - yAreaSize
        };
        if (e.clientY < yAreas.top || e.clientY > yAreas.bottom) {
          this.showHorizontalBars = true;
        } else {
          this.showHorizontalBars = false;
        }
        const xAreaSize = window.innerWidth * treshold;
        if (e.clientX < xAreaSize) {
          this.showThumbsBar = true;
        } else {
          this.showThumbsBar = false;
        }
      }
    });
  },
  methods: {
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.isFullscreen = false;
    },
    fullscreen(element) {
      if (element.requestFullscreen) {
        return element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        return element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        return element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        return element.msRequestFullscreen();
      }
    }
  }
};
