<template>
    <div class="search-preview-page">
        <BaseHeader/>
        <main class="container d-flex flex-column align-content-center">
            <template v-if="previewData.presentation">
                <h1 class="title">{{ previewData.presentation.name }}</h1>

                <div v-if="showPresentationPreview" class="presentation-preview-wrapper">
                    <div class="main-block">
                        <div class="workspace">
                            <div class="canvas" :class="{'canvas__fullscreen' : isFullscreen}">
                                <AnimationController
                                    :boxed="true"
                                    @init="acReady = true"
                                    :resizeFlag="resizeFlag"
                                    :animationPreview="false"
                                    ref="animation_controller"
                                    :vjsons="previewData.presentation"
                                    @slideindexchanged="activeSlide = $event"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="previewData.allSlides.length > 0" class="bottom-bar">
                        <div class="handlers">
                            <button @click="prevElem()" :class="{'disabled-btn': atTimelineStart}">
                                <inline-svg width="18" height="16" :src="require('@/assets/icon/chevron-left.svg')"/>
                            </button>

                            <div class="counter">
                                <span>{{ currentFrameText }}</span>
                                <span> / </span>
                                <span>{{ previewData.allSlides.length }}</span>
                            </div>

                            <button @click="nextElem()" :class="{'disabled-btn': atTimelineEnd}">
                                <inline-svg width="18" height="16" :src="require('@/assets/icon/chevron-right.svg')"/>
                            </button>
                        </div>
                    </div>
                </div>

                <GradientButton @click="forkPresentation">
                    {{ $t('forkPresentation') }}
                </GradientButton>

                <h2 class="description-title">{{ $t('presentaionContent') }}</h2>
                <div class="description" v-html="previewData.presentation.textContent"></div>
            </template>
        </main>
        <BaseFooter/>
        <PageLoader v-if="pageLoader" :text="pageLoader"/>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import BaseHeader from '@/components/Layouts/BaseHeader.vue';
import BaseFooter from '@/components/Layouts/BaseFooter.vue';
import AnimationController from '@/components/AnimationController';

import Fonts from '@/mixins/fonts';
import Fullscreen from '@/mixins/Fullscreen';
import AnimationControllerBar from '@/mixins/AnimationControllerBar';
import GradientButton from '@/components/styled/GradientButton.vue';
import PageLoader from '@/components/PageLoader.vue';
import Toast from '@/mixins/Toast';

export default {
    name: 'PresentationPreview',
    provide () {
        return {
            currentPage: this
        };
    },
    metaInfo () {
        return {
            title: this.pageData.title,
            meta: [
                { name: 'description', content: this.pageData.descrition},
                { property: 'og:title', content: this.pageData.title},
                { property: 'og:description', content: this.pageData.descrition},
                { property: 'og:url', content: `https://slide-lab.ru/presentation-preview/` + this.$route?.params?.id},
            ]
        };
    },
    components: {
        PageLoader,
        BaseHeader,
        BaseFooter,
        GradientButton,
        AnimationController
    },
    mixins: [
        Fonts,
        Toast,
        Fullscreen,
        AnimationControllerBar
    ],
    data () {
        return {
            activeSlide: 0,
            resizeFlag: false,
            isFullscreen: false,
            pageLoader: this.$t('presentationLoading'),
            pageData: {
                title: this.$t('defaultTitle'),
                descrition: this.$t('description.default')
            }
        };
    },
    computed: {
        ...mapState({
            previewData: state => state.viewer.previewData
        }),
        showPresentationPreview () {
            return this.previewData?.presentation?.meta && this.previewData?.allSlides?.length > 0;
        }
    },
    methods: {
        ...mapActions('viewer', [
            'getAllSlides',
            'getPresentationById',
            'forkCurrentPresentation'
        ]),
        resize () {
            this.resizeFlag = !this.resizeFlag;
        },
        nextElem () {
            this.$refs?.animation_controller.next();
        },
        prevElem () {
            this.$refs?.animation_controller.prev();
        },
        async forkPresentation () {
            if (this.$store.state.user.isAuthenticated) {
                this.pageLoader = this.$t('presentationCloning');
                const res = await this.forkCurrentPresentation(this.$route.params.id);
                this.pageLoader = null;

                if (res) {
                    this.showSuccessToast('presentationForked', 'top-center');
                }
            } else {
                this.$router.push('/login');
            }
        },

        setPageMeta () {
            const name = this.previewData.presentation.name;

            this.pageData = {
                title: name + this.$t('titleEnds'),
                descrition: this.$t('description.start') + name + this.$t('description.end')
            };
        }
    },
    async created () {
        if (!this.$route.params?.id) {
            throw new Error(this.$t('wrongLink'));
        }

        try {
            await this.getPresentationById(this.$route.params.id);
            this.setPageMeta();
            this.pageLoader = null;
        } catch (e) {
            throw new Error(this.$t(e.message));
        }
    },
    mounted () {
        window.addEventListener('resize', () => this.resize());
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/rfs.scss';
@import '@/assets/scss/preview.scss';

.search-preview-page {
    main {
        padding-top: 56px;
        min-height: calc(100vh - 60px);

        .title {
            color: #3A3B41;
            text-align: center;
            white-space: pre-line;
            @include font-size(37px);
            @include margin-top(40px);
            @include margin-bottom(40px);
            font-family: "Montserrat", sans-serif;
        }

        // Presentation...
        .presentation-preview-wrapper {
            display: flex;
            margin: 0 auto 70px;
            position: relative;
            background: #1c1e1f;
            align-items: stretch;
            flex-direction: column;

            width: 60vw;
            height: calc(60vw / 16 * 9);

            .main-block {
                flex: 1;
                top: 0px;
                left: 0px;
                z-index: 0;
                width: 100%;
                height: 100%;
                display: flex;
                cursor: pointer;
                position: absolute;
                box-sizing: content-box;
                border: 1px solid #3A3B41;

                .workspace {
                    flex: 1;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    width: calc(100% - 230px);

                    .canvas {
                        flex: 1;
                        display: flex;
                        overflow: hidden;
                        position: relative;
                        background: #1c1e1f;

                        .animation-container {
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-color: #1d1f20;

                            .bg-trimmer {
                                z-index: 0;
                                width: 100%;
                                height: 100%;
                                display: block;
                                overflow: hidden;
                                position: absolute;

                                .backgrounds {
                                    z-index: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    position: absolute;
                                }
                            }

                            .slides-trimmer {
                                top: 50%;
                                left: 50%;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                position: absolute;
                                transform: translate(-50%, -50%);

                                .slides {
                                    z-index: 1;
                                    overflow: hidden;
                                    position: relative;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-bar {
                left: 0;
                right: -2px;
                height: 45px;
                bottom: -45px;
                display: flex;
                position: absolute;
                justify-content: center;
                background-color: #3A3B41;

                .handlers {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    button {
                        border: none;
                        display: flex;
                        cursor: pointer;
                        box-shadow: none;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;

                        svg {
                            opacity: 0.6;
                            fill: #ffffff;
                        }
                    }

                    .counter {
                        opacity: 0.6;
                        color: #ffffff;
                    }
                }
            }

            @media (max-width: 768px) {
                width: 540px;
                height: calc(540px / 16 * 9);
            }

            @media (max-width: 576px) {
                width: 90vw;
                height: calc(90vw / 16 * 9);
            }
        }

        .description-title {
            color: #3A3B41;
            text-align: center;
            white-space: pre-line;
            @include font-size(32px);
            @include margin-top(30px);
            @include margin-bottom(30px);
            font-family: "Montserrat", sans-serif;
        }

        .description {
            @include margin-bottom(30px);

            p {
                margin-top: 0;
                color: #3A3B41;
                font-weight: 300;
                @include font-size(18px);
                @include margin-bottom(12px);
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}
</style>

<i18n>
{
    "en": {
        "presentaionContent": "Presentaion сontent:",
        "wrongLink": "Неверный адрес презентации, попробуем снова?",
        "presentationNotFound": "Презентация не найдена, попробуем снова?",
        "present": "Present",
        "forkPresentation": "Fork presentation",
        "exit": "Exit",
        "presentationLoading": "Presentation loading...",
        "presentationCloning": "Presentation copying...",
        "presentationForked": "Presentation copied!",

        "defaultTitle": "Presentation | SlideLab",
        "titleEnds": " - presentation | SlideLab",
        "description": {
            "default": "Download the finished presentation. Editor and bank of ready-made presentations SlideLab.",
            "start": "Download the finished presentation \"",
            "end": "\". Editor and bank of ready-made presentations SlideLab."
        }
    },
    "ru": {
        "presentaionContent": "Содержимое презентации:",
        "wrongLink": "Неверный адрес презентации, попробуем снова?",
        "presentationNotFound": "Презентация не найдена, попробуем снова?",
        "present": "Презентация",
        "forkPresentation": "Скопировать",
        "exit": "Выход",
        "presentationLoading": "Загрузка презентации...",
        "presentationCloning": "Копирование презентации...",
        "presentationForked": "Презентация скопирована!",

        "defaultTitle": "Презентация | SlideLab",
        "titleEnds": " - презентация | SlideLab",
        "description": {
            "default": "Скачать готовую презентацию. Редактор и банк готовых презентаций SlideLab.",
            "start": "Скачать готовую презентацию \"",
            "end": "\". Редактор и банк готовых презентаций SlideLab."
        }
    }
}
</i18n>
